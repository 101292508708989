import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiLinux,
  SiVisualstudiocode,
  SiPostman,
  SiHeroku,
  SiVercel,
  SiWindows,
  SiEclipseide,
  SiAndroidstudio
} from "react-icons/si";
import "./Toolstack.css"; // Import custom CSS styles

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode className="vscode-icon" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiLinux className="linux-icon" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiWindows className="windows-icon" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostman className="postman-icon" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVercel className="vercel-icon" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiHeroku className="heroku-icon" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiEclipseide className="eclipse-icon" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAndroidstudio className="androidstudio-icon" />
      </Col>
    </Row>
  );
}

export default Toolstack;
