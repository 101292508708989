import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import whatsappbot from "../../Assets/Projects/whatsappbot.png";
import newsScrapper from "../../Assets/Projects/newsScrapper.png";
import torrentScrapper from "../../Assets/Projects/torrentScrapper.png";
import tictactoy from "../../Assets/Projects/tictactoy.png";
import radio from "../../Assets/Projects/radio.png";
import randomNumber from "../../Assets/Projects/randomNumber.png";
import loveCalculator from "../../Assets/Projects/loveCalculator.png";
import ripto from "../../Assets/Projects/ripto.png";
import geolocator from "../../Assets/Projects/GeoLocator.png";
import cookify from "../../Assets/Projects/Cookify-Dark.jpg";
import elixrai from "../../Assets/Projects/Elixr-AI.jpg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={elixrai}
              isBlog={false}
              title="Elixr AI"
              description="Elixr AI is a cutting-edge AI application that leverages Google's Gemini AI model to provide a seamless and intuitive experience."
              ghLink="https://github.com/mu-fazil-vk/Elixr-AI-Info"
            />
          </Col>

        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={cookify}
              isBlog={false}
              title="Cookify"
              description="Cookify is a recipe finder app designed to help users discover and explore various recipes based on their cooking skill level and preferences."
              ghLink="https://github.com/mu-fazil-vk/Cookify-Readme"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ripto}
              isBlog={false}
              title="Ripto chat app"
              description="Ripto is a one to one chat app that allows you to communicate with your friends and loved ones in real time. With Ripto, you can send stickers, emojis, and images to express yourself in a fun and engaging way"
              ghLink="https://github.com/mu-fazil-vk/Ripto"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={geolocator}
              isBlog={false}
              title="GeoLocator"
              description="GeoLocator is a simple and fast IP location finder app that allows you to find the geographical location of an IP address, including the continent, country, city, and more. With GeoLocator, you can also see the location of the IP address on a map, giving you a visual representation of the data."
              ghLink="https://github.com/mu-fazil-vk/GeoLocator"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={loveCalculator}
              isBlog={false}
              title="Love Calculator"
              description="It is an application created in Flutter. When we enter any two names, we receive a love percentage, as well as a suggestion as to whether the relationship is good or bad. It is only for fun."
              ghLink="https://github.com/mu-fazil-vk/Love-Calculator"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={whatsappbot}
              isBlog={false}
              title="WhatsApp Bot"
              description="It is a WhatsApp bot that reply automatically when we send the commands. And there are many more features like AI chat reply."
              ghLink="https://github.com/mu-fazil-vk/whatsapp-bot"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={newsScrapper}
              isBlog={false}
              title="News Scrapper"
              description="News Scrapper bot scrape new newses from popular Malayalam websites and send to the specific WhatsApp groups. I used various methods and libraries for it."
              ghLink="https://github.com/mu-fazil-vk/News-Scrapper-Bot-Pro"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={torrentScrapper}
              isBlog={false}
              title="Torrent Scrapper"
              description="It is a scrapper that scrape new movies from torrenty site. It always check that there is any new movies available and if it available then it will download that movie and upload into given telegram channel."
              ghLink="https://github.com/mu-fazil-vk/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={tictactoy}
              isBlog={false}
              title="Tic Tac Toy"
              description="Tic Tac Toy is a simple game created with html,css and javascript. You can play it through your browser."
              ghLink="https://github.com/mu-fazil-vk/tic-tac-toy"
              demoLink="https://mu-fazil-vk.github.io/tic-tac-toy/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={radio}
              isBlog={false}
              title="Radio Player"
              description="It is a 24x7 Radio player that plays from zeno.fm. It is created by using only html and css."
              ghLink="https://github.com/mu-fazil-vk/Faz-Radio"
              demoLink="https://mu-fazil-vk.github.io/Faz-Radio/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={randomNumber}
              isBlog={false}
              title="Random Number Picker"
              description="Random Number Picker is a simple website that pic a number randomly and displays to you. It is made for fun."
              ghLink="https://github.com/mu-fazil-vk/Random-Number-Picker"
              demoLink="https://mu-fazil-vk.github.io/Random-Number-Picker/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
