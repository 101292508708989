import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiJsBadge,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiPython,
  DiGit,
  DiAndroid,
  DiApple,
  DiDatabase,
  DiGoogleCloudPlatform,
  DiJava
} from "react-icons/di";
import {
  SiFirebase,
  SiFlutter,
  SiC,
  SiDart,
  SiKotlin
} from "react-icons/si";
import "./Techstack.css"; // Import custom CSS styles

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiFlutter className="flutter-icon" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiDart className="dart-icon" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJsBadge className="js-icon" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs className="nodejs-icon" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiReact className="react-icon" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMongodb className="mongodb-icon" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiC className="c-icon" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit className="git-icon" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFirebase className="firebase-icon" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiPython className="python-icon" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiAndroid className="android-icon" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiApple className="apple-icon" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiDatabase className="database-icon" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGoogleCloudPlatform className="gcp-icon" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiAndroid className="android-icon" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJava className="java-icon" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiKotlin className="kotlin-icon" />
      </Col>
    </Row>
  );
}

export default Techstack;
